
import { Vue, Component } from 'vue-property-decorator'
import { IssueDetail, FileInfo, IssueTypeDetail } from '@/types/inspection'
import { ElForm } from 'element-ui/types/form'
import UploadFile from '@/components/uploadFile/Index.vue'
import { oneDecimal } from '@/utils/validate'
import { DicInfo, DicList } from '../../types/common'

@Component({
  components: { UploadFile }
})
export default class Add extends Vue {
  private isAdding = false
  private issueTypeList: Array<IssueTypeDetail> = []
  private rectifierList = []
  private patrolTypeList: DicInfo[] = []
  private issueInfo: IssueDetail = {
    issueTypeId: '',
    issueContent: '',
    deadLine: '',
    address: '',
    rectifierName: '',
    deduction: '0',
    resourceList: [],
    videoList: [],
    issueOpinion: '',
    patrolType: '0'
  }

  private props = {
    label: 'issueTypeName',
    children: 'childNode',
    value: 'issueTypeId'
  }

  private pickerOptions = {
    shortcuts: [
      {
        text: '今天',
        onClick (picker: any) {
          picker.$emit('pick', new Date())
        }
      },
      {
        text: '本周',
        onClick (picker: any) {
          const date = new Date()
          const day = date.getDay() === 0 ? 0 : (7 - date.getDay())
          date.setTime(date.getTime() + 3600 * 1000 * 24 * day)
          picker.$emit('pick', date)
        }
      },
      {
        text: '本月',
        onClick (picker: any) {
          const date = new Date()
          if (date.getMonth() === 11) {
            date.setFullYear(date.getFullYear() + 1, 0, 1)
            date.setTime(date.getTime() - 3600 * 1000 * 24)
          } else {
            date.setMonth(date.getMonth() + 1, 1)
            date.setTime(date.getTime() - 3600 * 1000 * 24)
          }
          picker.$emit('pick', date)
        }
      }]
  }

  private rules = {
    issueTypeId: [
      { required: true, message: '请选择问题类型' }
    ],
    issueContent: [
      { required: true, message: '请输入整改内容', whitespace: true }
    ],
    deadLine: [
      { required: true, message: '请选择整改截止日期', target: 'change' }
    ],
    address: [
      { required: true, message: '请输入整改位置', whitespace: true }
    ],
    rectifierName: [
      { required: true, message: '请选择整改人', target: 'change' }
    ],
    deduction: [
      { validator: oneDecimal, target: 'change' }
    ]
  }

  get patrolId () {
    return this.$route.params.patrolId as string || ''
  }

  $refs!: {
    issueInfo: ElForm;
    upload: any;
  }

  created (): void {
    this.getTypeList()
    this.getRectifierList()
    this.loadDict()
  }

  // 查询类型列表
  getTypeList () {
    this.$axios.get<IssueTypeDetail[]>(this.$apis.inspection.selectIssueTypeByList).then(res => {
      this.issueTypeList = res || []
    })
  }

  loadDict () {
    this.$axios.get<DicList>(this.$apis.common.selectDicByList, { dicType: 'patrolType' }).then(res => {
      this.patrolTypeList = res.patrolType
    })
  }

  getRectifierList () {
    this.$axios.get(this.$apis.inspection.selectUserByProcessNodeCodeByList, {
      processNodeCode: '1',
      processCode: '1', // 写死，代表巡查
      objectId: this.$route.params.patrolId
    }).then(res => {
      this.rectifierList = res.list || []
    })
  }

  // 如果分值大于100，则置为100
  deductionChange (val: string) {
    if (Number(val) > 100) {
      this.$set(this.issueInfo, 'deduction', 100)
    }
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.issueInfo.resourceList.push(file)
  }

  // 文件上传成功返回
  onSuccessVideo (file: FileInfo) {
    this.issueInfo.videoList.push(file)
  }

  imgRemove (index: number) {
    this.issueInfo.resourceList.splice(index, 1)
  }

  imgRemoveVideo (index: number) {
    this.issueInfo.videoList.splice(index, 1)
  }

  submitForm () {
    this.$refs.issueInfo.validate(valid => {
      if (valid) {
        this.addIssue()
      }
    })
  }

  addIssue () {
    this.isAdding = true
    this.$axios.post(this.$apis.inspection.insertIssue, {
      ...this.issueInfo,
      createResource: '0',
      patrolId: this.patrolId
    }).then(() => {
      this.$message.success('添加成功')
      this.goBack()
    }).finally(() => {
      this.isAdding = false
    })
  }

  goBack () {
    this.$router.push({ name: 'issueList' })
  }
}
